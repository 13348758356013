/* eslint-disable react/jsx-one-expression-per-line */
import * as React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Lead from '../components/Common/Lead';
import Services from '../components/Common/Services';

const Article = styled.article`
  position: relative;

  @media only screen and (min-width: 640px) {
    p {
      hyphens: auto;
      text-align: justify;
    }
  }

  h1,h2 {
    text-align: left;
  }

  figure {
    text-align: center;
  }

  dl {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0 0 20px;
  }

  dt {
    color: #c75784;
    font-weight: 900;
    width: 50px;
  }

  dd {
    padding: 0;
    width: calc(100% - 50px);
  }

  .columns {
    display: grid;
  }

  @media only screen and (min-width: 960px) {
    .columns {
      align-items: center;
      grid-gap: 50px;
      grid-template-columns: repeat(2, 1fr);

      &--top {
        align-items: flex-start;
      }
    }
  }
`;

const ListGrid = styled.ul`
  display: grid;
  list-style: none;
  padding: 0;

  figure {
    margin: 25px 0;
  }

  .gatsby-image-wrapper {
    margin: 0 auto;
    max-width: 428px;
  }

  @media only screen and (min-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
  }
`;

const siteMetadata = {
  title: 'Skup monet i banknotów – sklep numizmatyczny w Olsztynie',
  description: 'Skup starych monet, banknotów, medali i odznaczeń. Darmowa i niezobowiązująca wycena. Sprawdź, jak przygotować numizmaty do wyceny i poznaj wartość swojej kolekcji!',
  siteName: 'Monety Olsztyn',
  imageUrl: 'https://monety.olsztyn.pl/favicons/splash-screen.png',
  siteUrl: 'https://monety.olsztyn.pl/skup-monet-i-banknotow/',
  locale: 'pl_PL',
};

const SkupMonetIBanknotow = ({ data }) => (
  <>
    <Helmet htmlAttributes={{ lang: 'pl' }}>
      <title>{siteMetadata.title}</title>
      <meta name="description" content={siteMetadata.description} />
      <meta property="og:locale" content={siteMetadata.locale} />
      <meta property="og:site_name" content={siteMetadata.siteName} />
      <meta property="og:image" content={siteMetadata.imageUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={siteMetadata.siteUrl} />
      <meta property="og:title" content={siteMetadata.title} />
      <meta property="og:description" content={siteMetadata.description} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={siteMetadata.title} />
      <meta name="twitter:description" content={siteMetadata.description} />
      <meta name="twitter:image" content={siteMetadata.imageUrl} />

      <link rel="canonical" href={siteMetadata.siteUrl} />
    </Helmet>

    <Article>
      <div>
        <h1>Skup monet i&nbsp;banknotów</h1>

        <div className="columns columns--top">
          <div className="col--6">
            <Lead
              desc="Posiadasz stare monety, banknoty lub odznaczenia?<br/>Chętnie i&nbsp;bezpłatnie sprawdzimy autentyczność numizmatów oraz dokonamy rzetelnej i&nbsp;korzystnej&nbsp;wyceny!"
            />

            <p>W naszym skupie monet możesz sprzedać:</p>

            <ul>
              <li>monety i&nbsp;banknoty z&nbsp; okresu międzywojennego (1918–1939),</li>
              <li>monety złote i&nbsp;srebrne,</li>
              <li>monety zagraniczne,</li>
              <li>monety z&nbsp;okresu PRL,</li>
              <li>współczesne monety obiegowe, okolicznościowe i&nbsp;kolekcjonerskie,</li>
              <li>polskie banknoty obiegowe i&nbsp;kolekcjonerskie,</li>
              <li>medale okolicznościowe,</li>
              <li>odznaczenia wojskowe.</li>
            </ul>
          </div>

          <div
            className="col--6"
            style={{
              textAlign: 'center',
            }}
          >
            <GatsbyImage
              image={getImage(data.gold_coins)}
              alt="złote monety polskie i zagraniczne"
              placeholder="dominantColor"
              loading="eager"
            />
          </div>
        </div>
      </div>

      <section className="section-text">
        <h3>Numizmatyka Olsztyn – wycena monet i&nbsp;banknotów</h3>

        <p>Cena numizmatów zależy od wielu czynników. Przy dokładnej wycenie monet i&nbsp;banknotów niezwykle istotnymi kwestiami są:</p>

        <ul>
          <li>rzadkość występowania,</li>
          <li>stan zachowania,</li>
          <li>popularność wśród kolekcjonerów.</li>
        </ul>

        <p>W przypadku monet znaczenie ma również rodzaj stopu, waga i&nbsp;próba.</p>

        <p>
          Jak przygotować monety i&nbsp;banknoty do wyceny? Absolutnie
          {' '}
          <strong>nie należy czyścić</strong>
          , myć lub polerować monet! Czyszczenie może znacząco obniżyć cenę. Naturalna patyna, czyli ciemny nalot na monetach, nierzadko zwiększa atrakcyjność monet, co skutecznie podnosi ich wartość.
        </p>

        <h3>
          <span className="text-block">Skup monet – jeden cel,</span>
          {' '}
          różne możliwości
        </h3>

        <p>Na rynku numizmatyki w&nbsp;Olsztynie działamy już od wielu lat. Numizmatyka to nie tylko nasza praca, ale przede wszystkim pasja, która stale napędza nas do działania. Dzięki zdobytemu doświadczeniu i&nbsp;eksperckiej wiedzy numizmatycznej możemy potwierdzić autentyczność monet i&nbsp;banknotów oraz dokonać bezpłatnej wyceny.</p>

        <p>Gwarantujemy elastyczne i&nbsp;całkowicie indywidualne podejście do każdego klienta. Zależy nam na zapewnieniu maksymalnej wygody, dlatego oferujemy kilka możliwości otrzymania wyceny i&nbsp;sprzedaży numizmatów.</p>

        <cite className="info">
          Możesz odwiedzić nas osobiście lub odezwać się do nas na
          {' '}
          <a href="https://www.facebook.com/skup.monet.olsztyn" data-tippy-content="Odwiedź naszego Facebooka">Facebooku</a>
          .
          <br />
          Oferujemy także
          {' '}
          <strong>dojazd do klienta</strong>
          .
        </cite>
      </section>

      <section className="section-text">
        <h2>Stare monety</h2>

        <p>
          Posiadasz stare monety polskie lub zagraniczne? Doskonale się składa! Specjalizujemy się w&nbsp;skupie tych pozycji. Chętnie kupimy od Ciebie wszelkie egzemplarze, które pełniły funkcję środka płatniczego. W&nbsp;kręgu naszych zainteresowań znajdują się zarówno monety z&nbsp;okresu II&nbsp;Rzeczypospolitej, monety Polski Królewskiej jak i&nbsp;monety z&nbsp;okresu PRL. Przyjmujemy także stare monety zagraniczne.
        </p>

        <h3>Monety złote i&nbsp;srebrne</h3>

        <p>To pozycje cenne ze względu na wartość kruszcu, z&nbsp;którego zostały wykonane. Dokonujemy wyceny na podstawie bieżących cen rynkowych złota i&nbsp;srebra. Gwarantujemy precyzyjny kosztorys na podstawie stanu w&nbsp;jakim znajduje się moneta oraz aktualnych cen kruszcu. Jest również możliwość negocjacji cen, przy większej ilości pozycji.</p>

        <figure>
          <GatsbyImage
            image={getImage(data.silver_and_gold_coins)}
            alt="prezentacja niemieckich, rosyjskich i amerykańskich monet"
          />
          <figcaption>Srebrne i&nbsp;złote monety</figcaption>
        </figure>

        <h3>Monety okolicznościowe i&nbsp;kolekcjonerskie</h3>

        <p>Obszarem naszych zainteresowań są także współczesne monety kolekcjonerskie emitowane przez NBP. Są to monety kruszcowe, wykonane ze srebra lub złota. Czasami mogą mieć nietypowy nominał i&nbsp;kształt. Przykładem takiej monety jest Złota kula o&nbsp;nominale 2018&nbsp;zł, wybita przez Mennicę Polską na 100-lecie Niepodległości. Moneta wyemitowana w&nbsp;nakładzie zaledwie 100 sztuk, na początku 2021&nbsp;r. na jednej z&nbsp;platform aukcyjnych, została sprzedana za 129&nbsp;800&nbsp;zł.</p>

        <p>Na poniższym zdjęciu znajdują się następujące monety (od lewej):</p>

        <dl>
          <dt>1996</dt>
          <dd>
            – 10 zł – <em>Zygmunt II August</em>
          </dd>

          <dt>2001</dt>
          <dd>
            – 20 zł – <em>Szlak Bursztynowy</em>
          </dd>

          <dt>2007</dt>
          <dd>
            – 10 zł – <em>Konrad Korzeniowski</em>
          </dd>

          <dt>2018</dt>
          <dd>
            – 2018 zł – <em>Złota kula</em>
          </dd>

          <dt>2021</dt>
          <dd>
            – 50 zł – <em>230. Rocznica Konstytucji 3.&nbsp;maja</em>
          </dd>
        </dl>

        <figure>
          <GatsbyImage
            image={getImage(data.nbp_coins)}
            alt="Przykładowe monety wyemitowane przez NBP w latach 1996-2021"
          />
          <figcaption>Srebrne i&nbsp;złote monety emitowane przez NBP</figcaption>
        </figure>

        <h3>2 zł NG</h3>

        <p>Monety okolicznościowe, emitowane przez NBP w&nbsp;latach 1995-2014. Były wykonane ze stopu Nordic Gold, za wyjątkiem rocznika 1995, który był bity w&nbsp;całości z&nbsp;miedzioniklu. Chętnie skupujemy początkowe roczniki 1995-1999. Kontynuacją tej serii są okolicznościowe pięciozłotówki, emitowane od 2014 r.</p>
      </section>

      <section className="section-text">
        <h2>Banknoty</h2>

        <p>Wartość banknotów określana jest głównie przez wzgląd na stan zachowania. Doskonale zachowane egzemplarze są najbardziej porządane przez kolekcjonerów i&nbsp;zazwyczaj osiągają najwyższe ceny. Nie jest to jednak regułą – niektóre pozycje mimo słabego stanu są warte naprawdę sporo. Pozwól nam docenić wartość swojej kolekcji.</p>

        <ListGrid>
          <li>
            <figure className="text-center">
              <GatsbyImage
                image={getImage(data.polish_banknotes)}
                alt="Zbiór polskich banknotów"
              />
              <figcaption>
                Polskie banknoty wyemitowane między&nbsp;
                <span className="nowrap">1830–1939&nbsp;r.</span>
              </figcaption>
            </figure>
          </li>

          <li>
            <figure className="text-center">
              <GatsbyImage
                image={getImage(data.prl_banknotes)}
                alt="Polskie banknoty przed denominacją"
              />
              <figcaption>Banknot PRL oraz banknoty przed&nbsp;denominacją</figcaption>
            </figure>
          </li>
        </ListGrid>
      </section>

      <section className="section-text">
        <h2>Medale i&nbsp;odznaczenia</h2>

        <p>Najbardziej cenionymi medalami są egzemplarze przedwojenne, dziewiętnastowieczne i&nbsp;królewskie. Wycena efektywnych medali może bardzo pozytywnie zaskoczyć i&nbsp;z największą przyjemnością dokonamy jej dla Ciebie bezpłatnie i&nbsp;niezobowiązująco.</p>

        <p>W zakres naszej działalności wchodzą także odznaczenia wojskowe. Odznaki pułkowe, państwowe czy resortowe mogą przedstawiać sporą wartość materialną. Dodatkowe elementy – takie jak dokumenty, legitymacje, zawiadomienia o&nbsp;otrzymaniu odznaczeń, zdjęcia czy przedmioty należące do osoby odznaczonej – znacznie zwiększają atrakcyjność pozycji oraz jej wycenę.</p>

        <figure>
          <GatsbyImage
            image={getImage(data.military_decorations)}
            alt="Najwyższe polskie odznaczenia wojskowe"
          />
          <figcaption>Odznaki, ordery, medale</figcaption>
        </figure>
      </section>

      <section className="section-text">
        <h2>Stare pocztówki związane z&nbsp;Olsztynem</h2>

        <p>Pocztówki stanowią fascynujący element historii korespondencji. Od momentu, gdy pierwsza pocztówka została wysłana w&nbsp;1869 roku, stały się one popularnym środkiem komunikacji na całym świecie. Służyły jako sposób przekazywania pozdrowień, emocji i&nbsp;informacji między bliskimi i&nbsp;znajomymi.</p>

        <p>Początkowo pocztówki często przedstawiały zabytki, krajobrazy, architekturę lub charakterystyczne miejsca, dając odbiorcom wirtualną podróż. Ich wygląd ewoluował z&nbsp;czasem, od prostych monochromatycznych obrazków po kolorowe, artystyczne kompozycje. Pocztówki stały się symbolem podróży i&nbsp;eksploracji, pozwalając ludziom dzielić się swoimi przygodami z&nbsp;dalekich zakątków świata.</p>

        <figure className="text-center">
          <GatsbyImage
            image={getImage(data.old_card)}
            alt="Stara pocztówka z Olsztyna"
          />
          <figcaption>
            Na karcie przedstawiono najważniejsze budynki publiczne ówczesnego Olsztyna:<br />gmach poczty, sądów, gimnazjum oraz dyrekcji kolejowej. Rok około 1895-96.
          </figcaption>
        </figure>

        <p>Pierwsze pocztówki miały wydrukowany znak opłaty i&nbsp;miejsce na adres na jednej stronie, natomiast druga strona była przeznaczona dla treści korespondencji. Nieznane są dokładne szczegóły, kto jako pierwszy wpadł na pomysł umieszczenia ilustracji na kartach pocztowych. Jednakże w&nbsp;Niemczech przypisuje się ten pomysł oldenburskiemu księgarzowi i&nbsp;drukarzowi, Augustowi Schwartzowi. Podczas mobilizacji 16&nbsp;lipca 1870 roku wysłał on kartę pocztową ozdobioną niewielkim rysunkiem artylerzysty, umieszczonym na stronie adresowej.</p>

        <figure className="text-center">
          <GatsbyImage
            image={getImage(data.station)}
            alt="Stara pocztówka ukazująca Dworzec Główny w Olsztynie"
          />
          <figcaption>
            Dworzec Główny w&nbsp;Olsztynie około 1915&nbsp;r.
          </figcaption>
        </figure>

        <p>Około roku 1872 zaczęto umieszczać niewielkie i&nbsp;jeszcze prymitywne obrazki na stronie przeznaczonej na korespondencję. Idea ta szybko zyskała popularność, a&nbsp;ilustracje stawały się coraz bardziej różnorodne, pomysłowe i&nbsp;starannie zaprojektowane. Zajmowały coraz większą część karty pocztowej, dosłownie wypierając treść korespondencji na margines i&nbsp;stając się głównym elementem strony, która dotychczas była przeznaczona wyłącznie na tekst. W&nbsp;1904 roku, decyzją Światowego Związku Pocztowego przeniesiono miejsce przeznaczone na korespondencję na stronę adresową, dzieląc ją na połowę, pozostawiając drugą stronę pocztówki tylko dla ilustracji.</p>

        <p>
          Jeśli posiadasz <Link to="/stare-pocztowki/" title="Skup starych pocztówek Olsztyn">pocztówki związane z Olsztynem</Link>, z&nbsp;okresu międzywojennego lub starsze i&nbsp;chciałbyś je wycenić lub sprzedać zachęcamy do <Link to="/kontakt/">kontaktu</Link>.
        </p>
      </section>

      <section className="section-text section-text--no-border">
        <h2>Zapraszamy do kontaktu</h2>

        <p>
          Jeśli posiadasz numizmaty, które chcesz wycenić, po prostu wyślij do nas zdjęcia na <a href="mailto:kontakt@monety.olsztyn.pl">kontakt@monety.olsztyn.pl</a>.
        </p>

        <p>W&nbsp;zupełności wystarczy zdjęcie zbiorcze monet lub&nbsp;banknotów z&nbsp;obu stron. Dokonamy wstępnej wyceny Twojej kolekcji i&nbsp;przedstawimy szacunkowy kosztorys. Ostateczna cena będzie oczywiście zależała od potwierdzenia autentyczności i&nbsp;oceny stanu zachowania danego egzemplarza.</p>

        <p>Do każdej wyceny podchodzimy indywidualnie.</p>

        <p>U nas otrzymasz najkorzystniejszą ofertę.</p>
      </section>

      <Services />
    </Article>
  </>
);

export const query = graphql`
  query {
    gold_coins: file(relativePath: {regex: "/skup-zlotych-monet/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 435
        )
      }
    }
    silver_and_gold_coins: file(relativePath: {regex: "/srebrne-i-zlote-monety/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 800
        )
      }
    }
    nbp_coins: file(relativePath: {regex: "/srebrne-monety-nbp/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 915
        )
      }
    }
    polish_banknotes: file(relativePath: {regex: "/polskie-banknoty/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 428
        )
      }
    }
    prl_banknotes: file(relativePath: {regex: "/banknoty-prl/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 428
        )
      }
    }
    military_decorations: file(relativePath: {regex: "/odznaczenia-wojskowe.png/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 905
        )
      }
    }
    station: file(relativePath: {regex: "/dworzec-w-olsztynie/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 905
        )
      }
    }
    old_card: file(relativePath: {regex: "/stara-pocztowka/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 905
        )
      }
    }
  }
`;

export default SkupMonetIBanknotow;
